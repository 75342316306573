import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, catchError, map, of, tap, throwError } from "rxjs";
import { Estatistica } from "src/app/models/estatistica";
import { HttpClient } from "@angular/common/http";
import { ScaleStore } from "../Scale.store";
import { EndPoint, getEndpoint } from "src/app/models/endpoint";
import { LoadingService } from "../loading.service";
import { AuthStore } from "../Auth.store";
import { SugestaoService } from "./sugestao.service";

@Injectable()
export class SugestaoHttpService extends SugestaoService {

  constructor(
    private http: HttpClient,
    scaleStore: ScaleStore,
    private loadingService: LoadingService,
    private authStore: AuthStore
  ) {
    super(scaleStore);
  }

  //Add Loading

  public override sugerirRegraPost(sugestao: String): Observable<any> {
    this.loadingService.presentLoading();
    return this.http.post(`${getEndpoint(EndPoint.ESCALA)}/regras/sugestao`, {
      sugestao,
      userId: this.authStore.getAdminId()
    }).
      pipe(
        catchError((err: any) => {
          this.loadingService.dismissLoading();
          if (err.error.message) {
            this.loadingService.presentToast(err.error.message, 'danger')
          } else {
            const message = "Erro ao salvar sugestão"
            this.loadingService.presentToast(message)
          }

          return throwError(err);
        }),
        tap((r) => {
          console.log(r);
          this.loadingService.dismissLoading();
        }),
      )
  }

  public override carregaRegrasSugeridasGet(): Observable<any> {
    this.loadingService.presentLoading();
    return this.http.get(`${getEndpoint(EndPoint.ESCALA)}/regras/sugestao?userId=${this.authStore.getAdminId()}`).
      pipe(
        catchError((err: any) => {
          this.loadingService.dismissLoading();
          if (err.error.message) {
            this.loadingService.presentToast(err.error.message, 'danger')
          } else {
            const message = "Erro ao carregar regras sugerias"
            this.loadingService.presentToast(message)
          }
          return throwError(err);
        }),
        tap((r) => {
          this.loadingService.dismissLoading();
        }),
      )
  }

}
