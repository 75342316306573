import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loading!: HTMLIonLoadingElement;

  constructor(
    private loadingController: LoadingController,
    private alertController: AlertController,
    private toastController: ToastController
    ) { }

  async presentLoading(message: string = 'Carregando...') {
    this.loading = await this.loadingController.create({
      message,
      mode: 'ios'
    });

    await this.loading.present();
  }

  async presentLoadingWithTimeout(time: number) {
    this.loading = await this.loadingController.create({
      message: 'Aguarde...',
      mode: 'ios'
    });
    await this.loading.present();
    setTimeout(() => {
      this.loading.dismiss();
    }, time);
  }

  async dismissLoading() {
    return await this.loading.dismiss();
  }

  async presentAlertConfirm(header: string, subHeader: string, message: string, agreeFunction: () => void) {
    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      mode: 'ios',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
        }, {
          text: 'Sim',
          handler: () => agreeFunction()
        }
      ]
    });

    return await alert.present();
  }

  async presentAlertConfirmOrCancel(header: string, subHeader: string, message: string, agreeFunction: () => void, cancelFunction: () => void) {
    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      mode: 'ios',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
          handler: () => cancelFunction()
        }, {
          text: 'Sim',
          handler: () => agreeFunction()
        }
      ]
    });

    return await alert.present();
  }

  async presentToast(message: string, color: string = 'medium') {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      mode: 'ios',
      color: color,
    });
    toast.present();
  }

  async presentToastWithOptions() {
    const toast = await this.toastController.create({
      header: 'Toast header',
      message: 'Click to Close',
      mode: 'ios',
      position: 'top',
      buttons: [
        {
          side: 'start',
          icon: 'star',
          text: 'Favorite',
          handler: () => {
            console.log('Favorite clicked');
          },
        },
        {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }
}

