import { Observable, throwError } from "rxjs";
import { Escala } from "src/app/models/escala";
import { Estatistica } from "src/app/models/estatistica";
import { ScaleStore } from "../Scale.store";

export abstract class SugestaoService {


  protected scaleStore: ScaleStore;
  constructor(scaleStore: ScaleStore) {
    this.scaleStore = scaleStore;
  }

  protected handleError(error: any) {
    return throwError(error);
  }

  protected log(message: string) {
    console.log('============= message response ============');
    console.log(message);
  }

  public abstract sugerirRegraPost(sugestao: String): Observable<any>;
  public abstract carregaRegrasSugeridasGet(): Observable<any>;

}
