import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { RequestChange } from 'src/app/models/request-change';
import { TurnoService } from 'src/app/services/turno/turno.service';

@Component({
  selector: 'app-scale-change-requests',
  templateUrl: './scale-change-requests.component.html',
  styleUrls: ['./scale-change-requests.component.scss'],
})
export class ScaleChangeRequestsComponent implements OnInit {

  constructor(private modalController: ModalController, private turnoService: TurnoService) { }
  solicitacoes$!: Observable<RequestChange[]>

  ngOnInit() {
    this.solicitacoes$ = this.turnoService.carregarSolicitacoesTroca(1);
  }


  public cancel() {
    return this.modalController.dismiss(null, 'cancel');
  }

}
