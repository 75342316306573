import { Injectable } from "@angular/core";
import { TurnoService } from "./turno.service";
import { BehaviorSubject, Observable, catchError, map, of, tap, throwError } from "rxjs";
import { Regra } from "src/app/models/regra";
import { RequestChange } from "src/app/models/request-change";
import { Setor } from "src/app/models/setor";
import { Turno } from "src/app/models/turno";
import { HttpClient, HttpParams } from "@angular/common/http";
import { EndPoint, getEndpoint } from "src/app/models/endpoint";
import { LoadingService } from "../loading.service";
import { ScaleStore } from "../Scale.store";
import { ScaleInUseService } from "../scale-in-use.service";
import { itemColaborador } from "src/app/models/itens_colaboradores";
import { addColaborador } from "src/app/models/add-colaborador";

@Injectable()
export class TurnoHttpService extends TurnoService {

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    scaleStore: ScaleStore,
    private scaleInUse: ScaleInUseService
  ) {
    super(scaleStore);
  }

  private itemColaboradorSubject = new BehaviorSubject<itemColaborador>({
    colaboradorId: 1,
    dataEscala: '123',
    escalaId: 1,
    ferias: false,
    id: 1,
    licencaMedica: false,
    ponto: true,
    setorId: 1,
    setorTrocadoId: 1,
    teveTroca: false,
    trocouSetor: false,
    turnoId: 1
  });
  itemColaborador$ = this.itemColaboradorSubject.asObservable();

  public carregaTurnos(escalaId: number): Observable<Turno[]> {
    return this.http.get<Turno[]>(`${getEndpoint(EndPoint.TURNO)}?escalaIdId=${escalaId}`).pipe(
      map((res) => {
        if (res) {
          return res;
        }
        return [];
      }),
      catchError((err: any) => {
        const message = "Erro ao carregar os turnos."
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((res) => {
        this.scaleStore.setTurnos(res);
      })
    )
  }

  public override criaTurno(turno: Turno): Observable<Turno> {
    return this.http.post<Turno>(`${getEndpoint(EndPoint.TURNO)}`, {
      ...turno,
      escalaId: this.scaleInUse.getEscalaId()
    })
      .pipe(
        map((res) => {
          if (res) {
            return res;
          }
          return {} as Turno;
        }),
        catchError((err: any) => {
          const message = "Erro ao criar um turno"
          this.loadingService.presentToast(message)
          return throwError(err);
        }),
        tap((res) => {
          this.scaleStore.addTurno(res);
        })
      )
  }
  public override atualizarTurno(turnoId: number, newTurno: Turno): Observable<Turno> {
    return this.http.put<Turno>(`${getEndpoint(EndPoint.TURNO)}`, {
      ...newTurno,
      escalaId: this.scaleInUse.getEscalaId(),
      id: turnoId
    })
      .pipe(
        map((res) => {
          if (res) {
            return res;
          }
          return {} as Turno;
        }),
        catchError((err: any) => {
          const message = "Erro ao atualizar o turno"
          this.loadingService.presentToast(message)
          return throwError(err);
        }),
        tap((res) => {
          this.scaleStore.updateTurno(res);
        })
      )
  }
  public override filtrarTurno(turnoId: number): Observable<Turno> {
    throw new Error("Method not implemented.");
  }
  public override deletarTurno(turnoId: number): void {
    this.loadingService.presentLoading();
    this.http.delete(`${getEndpoint(EndPoint.TURNO)}?id=${turnoId}`)
      .pipe(
        tap(() => {
          this.loadingService.dismissLoading();
          this.loadingService.presentToast('Turno deletado com sucesso')
          this.scaleStore.deleteTurno(turnoId);
        }),
        catchError((e) => {
          this.loadingService.dismissLoading();
          return throwError(e);
        })
      )
      .subscribe()
  }


  public override carregaSetores(escalaId: number): Observable<Setor[]> {
    return this.http.get<Setor[]>(`${getEndpoint(EndPoint.SETOR)}?escalaId=${escalaId}`).pipe(
      map((res) => {
        if (res) {
          return res;
        }
        return [];
      }),
      catchError((err: any) => {
        const message = "Erro ao carregar os setores."
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((res) => {
        this.scaleStore.setSetores(res);
      })
    )
  }



  public override criaSetor(setor: Setor): Observable<Setor> {
    return this.http.post<Setor>(`${getEndpoint(EndPoint.SETOR)}`, {
      ...setor,
      escalaId: this.scaleInUse.getEscalaId()
    })
      .pipe(
        map((res) => {
          if (res) {
            return res;
          }
          return {} as Setor;
        }),
        catchError((err: any) => {
          const message = "Erro ao criar um setor"
          this.loadingService.presentToast(message)
          return throwError(err);
        }),
        tap((res) => {
          this.scaleStore.addSetor(res);
          this.scaleInUse.addSetorInEscaleInUse(res)
        })
      )
  }
  public override atualizarSetor(setorId: number, newSetor: Setor): Observable<Setor> {
    return this.http.put<Setor>(`${getEndpoint(EndPoint.SETOR)}`, {
      ...newSetor,
      escalaId: this.scaleInUse.getEscalaId(),
      id: setorId
    })
      .pipe(
        map((res) => {
          if (res) {
            return res;
          }
          return {} as Setor;
        }),
        catchError((err: any) => {
          const message = "Erro ao atualizar o setor"
          this.loadingService.presentToast(message)
          return throwError(err);
        }),
        tap((res) => {
          this.scaleStore.updateSetor(res);
          this.scaleInUse.updateSetorInEscaleInUse(res.id, res);
        })
      )
  }
  public filtrarSetor(setorId: number): Observable<Setor> {
    throw new Error("Method not implemented.");
  }
  public deletarSetor(setorId: number): void {
    this.loadingService.presentLoading();
    this.http.delete(`${getEndpoint(EndPoint.SETOR)}?id=${setorId}`)
      .pipe(tap(() => {
        this.loadingService.dismissLoading();
        this.loadingService.presentToast('Setor deletado com sucesso')
        this.scaleStore.deleteSetor(setorId)
        this.scaleInUse.removeSetorInEscaleInUse(setorId)
      }),
        catchError((e: any) => {
          this.loadingService.dismissLoading();
          return throwError(e);
        })
      )
      .subscribe()
  }

  public carregaSetorPorEscala(escalaId: number, setorId: number): Observable<Setor> {
    return this.http.get<Setor>(`${getEndpoint(EndPoint.SETOR)}/getSetorEscala?escalaId=${escalaId}&setorId=${setorId}`).pipe(
      map((res) => {
        if (res) {
          return res;
        }
        return {} as Setor;
      }),
      catchError((err: any) => {
        const message = "Erro ao carregar os setores."
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((res) => {
        if (this.scaleStore.containsSetor(res.id)) {
          this.scaleStore.updateSetor(res)
        } else {
          this.scaleStore.addSetor(res);
        }
        // this.scaleStore.setSetores(res);
      })
    )
  }


  public override criaSolicitacaoTroca(solicitacao: RequestChange): Observable<RequestChange> {
    throw new Error("Method not implemented.");
  }
  public override carregarSolicitacoesTroca(escalaId: number): Observable<RequestChange[]> {
    return of([{
      id: 1,
      escalaId: 1,
      data: new Date().toString(),
      dataTroca: new Date().toString(),
      turno: {
        to: 'D - 05:00 a 15:00',
        from: 'M - 05:00 a 12:00'
      },
      setor: {
        to: 'UTI GERAL',
        from: 'SEMI INTENSIVA GERAL'
      },
      colaboradores: {
        to: {
          id: 1,
          ativo: true,
          conselhoProf: '1',
          cpf: '000-000-000.00',
          deletado: false,
          email: 'teste@trocaturno.com.br',
          funcao: 'TESTE',
          cargaHoraia: 0,
          afastamentos: [],
          dataNasc: '00/00/0000',
          gestor: true,
          loginId: 1,
          matricula: '123',
          nome: 'Anne Karine Menezes S. Batista',
          senha: '123'
        },
        from: {
          id: 1,
          ativo: true,
          conselhoProf: '1',
          cpf: '000-000-000.00',
          dataNasc: '00/00/0000',
          deletado: false,
          email: 'teste@trocaturno.com.br',
          funcao: 'TESTE',
          cargaHoraia: 0,
          afastamentos: [],
          gestor: true,
          loginId: 1,
          matricula: '123',
          nome: 'Daniela de Souza Pinto',
          senha: '123'
        }
      },
      status: 'PENDENTE'
    }])
  }

  public override carregaItemColaborador(): Observable<itemColaborador> {
    return this.itemColaborador$;
  }

  public override atualizarItemColaborador(itemId: number, newItem: itemColaborador): Observable<itemColaborador> {
    // const message = "conflito de interjonada (12h)"
    // this.loadingService.presentToast(message, 'danger')
    // return throwError({ error: 'conflito de interjonada (12h)'});
    return this.http.put<itemColaborador>(`${getEndpoint(EndPoint.ITEM_COLABORADOR)}/update`, {
      ...newItem,
      escalaId: this.scaleInUse.getEscalaId(),
      id: itemId
    })
      .pipe(
        map((res) => {
          if (res) {
            return res;
          }
          return {} as itemColaborador;
        }),
        catchError((err: any) => {
          const message = "Erro ao atualizar o item"
          this.loadingService.presentToast(message)
          return throwError(err);
        }),
        tap((res) => {
          // TODO: UPDATE STORE
          console.log(res);
        })
      )
  }

  public criaItemColaborador(item: itemColaborador): Observable<itemColaborador> {
    return this.http.post<itemColaborador>(`${getEndpoint(EndPoint.ITEM_COLABORADOR)}/save`, {
      ...item,
      escalaId: this.scaleInUse.getEscalaId()
    }).pipe(
      map((res) => {
        if (res) {
          return res;
        }
        return {} as itemColaborador;
      }),
      catchError((err: any) => {
        if (err.error.message) {
          this.loadingService.presentToast(err.error.message, 'danger')
        } else {
          const message = "Erro ao criar um item"
          this.loadingService.presentToast(message)
        }

        return throwError(err);
      }),
      tap((res) => {
        // TODO: COLOCAR NO STORE;
        console.log(res);
      })
    )

  }

  public override deletarItemColaborador(itemId: number): void {
    this.http.delete(`${getEndpoint(EndPoint.ITEM_COLABORADOR)}/delete?id=${itemId}`)
      .pipe(tap(() => {
        this.loadingService.presentToast('Item deletado com sucesso')
        // this.scaleStore.deleteSetor(setorId)
        // this.scaleInUse.removeSetorInEscaleInUse(setorId)
      }))
      .subscribe()
  }

  public override deletarItensColaborador(itens: number[]): void {
    if (itens.length < 1) {
      return;
    }
    let params = new HttpParams();
    itens.forEach((i) => params = params.append('ids', i))
    console.log(params);
    this.http.delete(`${getEndpoint(EndPoint.ITEM_COLABORADOR)}/delete/many`, { params })
      .pipe(tap(() => {
        itens.length > 1 ?
          this.loadingService.presentToast('Itens deletados com sucesso') :
          this.loadingService.presentToast('Item deletado com sucesso')
        // this.scaleStore.deleteSetor(setorId)
        // this.scaleInUse.removeSetorInEscaleInUse(setorId)
      }))
      .subscribe()
  }

  public override adicionarColaborador(colaboradorData: addColaborador): Observable<addColaborador> {
    return this.http.post<addColaborador>(`${getEndpoint(EndPoint.SETOR)}/adicionaColaborador`, {
      ...colaboradorData
    })
      .pipe(
        map((res) => {
          if (res) {
            return res;
          }
          return {} as addColaborador;
        }),
        catchError((err: any) => {
          const message = "Erro ao adicionar um colaborador na escala"
          this.loadingService.presentToast(message)
          return throwError(err);
        }),
        tap((res) => {
          console.log(res, 'add-colaborador');
          // this.scaleStore.addTurno(res);
        })
      )
  }

}
