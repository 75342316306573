<ion-toolbar>
  <ion-buttons slot="end">
    <ion-button (click)="close()"
      ><ion-icon name="close-outline"></ion-icon
    ></ion-button>
  </ion-buttons>
</ion-toolbar>
<ion-content class="ion-padding">
  <div class="container">
    <h1>TURNOS</h1>

    <div class="shifts">
      <div class="shift">
        <p class="color">Cor</p>
        <p type="text" class="shift-date">Hora Início</p>
        <span style="width: 8px;"></span>
        <p type="text" class="shift-date">Hora Fim</p>
        <p class="shift-name" >Descrição</p>
        <p class="shift-abbreviation" >Sigla</p>
        <p class="total-view">Total</p>
        <div class="checkbox" style="width: 54px;">
          <p style="margin-left: 1.875rem;">Ativo</p>
        </div>
        <div class="actions">
        </div>
      </div>
      <ion-grid>
        <turno-item *ngIf="isInput" [isInput]="isInput" (FormEmitter)="isInput = $event"></turno-item>
        <div *ngIf="(turnos$ | async) as turnos">
          <turno-item [isInput]="false" *ngFor="let item of turnos" [turno]="item"></turno-item>
        </div>
      </ion-grid>
      <div class="create" (click)="showInput()"><ion-icon name="add-outline"></ion-icon> criar turno</div>
    </div>
  </div>


</ion-content>
