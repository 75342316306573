import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { tap } from 'rxjs';
import { Colors, getColor, getColors, getCorPorValor } from 'src/app/models/colors';
import { Setor } from 'src/app/models/setor';
import { LoadingService } from 'src/app/services/loading.service';
import { ScaleInUseService } from 'src/app/services/scale-in-use.service';
import { TurnoService } from 'src/app/services/turno/turno.service';
import { PopoverSelectColorComponent } from 'src/app/shared/components/popover-select-color/popover-select-color.component';

@Component({
  selector: 'setor-item',
  templateUrl: './setor-item.component.html',
  styleUrls: ['./setor-item.component.scss'],
})
export class SetorItemComponent implements OnInit {

  @Input() isForm!: boolean;
  @Input() Setor!: Setor;
  @Output() FormEmitter: EventEmitter<boolean> = new EventEmitter<boolean>()

  colorSelected = '#1FBAA4'
  active: boolean = true;
  setorName!: string;

  isEdit: boolean = false;
  isSaving = false;

  formGroup!: FormGroup;

  constructor(
    private loadingCtrl: LoadingService,
    private turnoService: TurnoService,
    private popoverCtrl: PopoverController,
    private scaleInUse: ScaleInUseService
  ) { }

  ngOnInit() {
    if (this.Setor) {
      this.active = this.Setor.ativo
      this.colorSelected = this.getColor(this.Setor.cor) || '#E54997';
      this.setorName = this.Setor.nome
    }
    if (this.isForm) {
      this.createForm()
    }
  }

  createForm() {
    this.formGroup = new FormGroup({
      nome: new FormControl(this.Setor ? this.Setor.nome : '', [Validators.required]),
      cor: new FormControl(this.Setor ? this.Setor.cor : 'color1', [Validators.required]),
      idEscala: new FormControl(0),
      ativo: new FormControl(this.Setor ? this.Setor.ativo : true, [Validators.required]),
    })
    this.colorSelected = '#1FBAA4'
    this.turnoService.getCoresSelecionadas(this.scaleInUse.getValues()!.escala!.id!, true).pipe(
      tap((r) => {
        const colors = this.getAllColors().map((c) => getCorPorValor(c));
        const notSelected = colors.filter(c => !r.includes(c))
        this.colorSelected = getColor(notSelected[0]);
        if (!this.isEdit) {
          this.formGroup.patchValue({ cor: notSelected[0] })
        }
      })
    ).subscribe()

    if (this.isEdit) {
      this.colorSelected = this.getColor(this.Setor.cor)!;
    }
  }

  getColor(name: string) {
    return getColor(name)
  }

  getAllColors() {
    return getColors();
  }


  submit() {
    console.log(this.formGroup.value)
    if (this.formGroup.invalid) {
      this.loadingCtrl.presentToast('Por favor, preencha todos os campos.')
      return;
    }
    this.isSaving = true;
    console.log(this.isEdit, 'in edditing')
    if (!this.isEdit) {
      this.turnoService.criaSetor({
        ...this.formGroup.value
      }).pipe(
        tap(() => {
          this.isSaving = false;
          this.loadingCtrl.presentToast('Setor criado com sucesso')
          this.formGroup.reset()
          this.createForm()
          this.isForm = false;
          this.FormEmitter.emit(false);
        })
      ).subscribe()
    } else {
      this.turnoService.atualizarSetor(this.Setor.id, {
        ...this.formGroup.value
      }).pipe(
        tap((res) => {
          this.isSaving = false;
          this.loadingCtrl.presentToast('Setor atualizado com sucesso')
          this.isEdit = false;
          this.isForm = false;
          this.formGroup.reset();
          this.Setor = res;
        })
      ).subscribe()
    }

  }

  setEditMode() {
    this.isEdit = true;
    this.isForm = true;
    this.createForm();
  }

  cancel() {
    if (this.isEdit) {
      this.formGroup.reset();
      this.isEdit = false;
      this.isForm = false;
      return;
    }
    this.formGroup.reset();
    this.createForm();
    this.isForm = false;
    this.FormEmitter.emit(false);
  }

  deleteSetor() {

    this.loadingCtrl.presentAlertConfirm(
      'Deletar Setor?', '', 'Deseja realmente deletar este Setor?',
      () => {
        this.turnoService.deletarSetor(this.Setor.id);
      }
    )
  }

  async openPopover(event: any) {
    const popover = await this.popoverCtrl.create({
      component: PopoverSelectColorComponent,
      side: 'top',
      mode: 'ios',
      event,
      componentProps: {
        scaleId: this.scaleInUse.getEscalaId(),
        isSector: true
      }
    })

    popover.present();

    popover.onDidDismiss().then((r) => {
      if (r.data != undefined) {
        this.colorSelected = getColor(r.data);
        this.formGroup.patchValue({ cor: r.data })
      }
    })
  }


}
