import { Injectable } from '@angular/core';
import { UsuarioService } from '../usuario/usuario.service';
import { Router } from '@angular/router';
import { LocalService } from '../local/local.service';
import { EmpresaService } from '../empresa/empresa.service';
import { catchError, map, tap, throwError } from 'rxjs';
import { ColaboradorService } from '../colaborador/colaborador.service';
import { EscalaService } from '../escala/escala.service';
import { Local } from 'src/app/models/local';
import { LoadingService } from '../loading.service';
import { Usuario } from 'src/app/models/usuario';
import { AdminService } from '../admin/admin.service';
import { UserAdmin } from 'src/app/models/useradmin';
import { Empresa } from 'src/app/models/empresa';
import { Escala } from 'src/app/models/escala';
import { TurnoService } from '../turno/turno.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private usuarioService: UsuarioService,
    private router: Router,
    private localService: LocalService,
    private empresaService: EmpresaService,
    private colaboradorService: ColaboradorService,
    private escalaService: EscalaService,
    private adminUserService: AdminService,
    private loadingService: LoadingService,
    private turnoService: TurnoService
  ) { }

  empresaId!: number;


  login(email: string, password: string) {
    this.usuarioService.login(email, password)
      .pipe(
        map((res) => {
          if (res) {
            return res;
          }
          return {} as Usuario
        }),
        tap((data) => {
          if (!data) return;
          console.log(data, 'logged')
          this.router.navigate([''])
          this.carregarEmpresas(data.userId!)
        }),
      ).subscribe();
  }

  signup(email: string, password: string, cpf: string, funcao: string, name: string) {
    this.usuarioService.signup(email, password)
      .pipe(
        map((res) => {
          if (res) {
            return res;
          }
          return {} as Usuario
        }),
        tap((data) => {
          this.loginForSignUp(email, password, cpf, funcao, name)
        })
      ).subscribe()
  }

  private loginForSignUp(email: string, password: string, cpf: string, funcao: string, name: string) {
    this.usuarioService.login(email, password)
      .pipe(
        map((res) => {
          if (res) {
            return res;
          }
          return {} as Usuario
        }),
        tap((data) => {
          if (!data) return;
          this.criaUserAdm(data, cpf, funcao, name);
        }),
      ).subscribe();
  }

  async setEmpresaSelect(empresaId: number) {
    await this.loadingService.presentLoading();
    this.empresaService.getEmpresas().pipe(
      map((empresas) => {
        if (empresas) {
          return empresas.find(empresa => empresa.id == empresaId)
        }
        return {} as Empresa
      }),
      catchError((err: any) => {
        this.loadingService.dismissLoading();
        const message = "Erro ao alternar de empresa"
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap(async (res) => {
        if (res) {
          this.empresaService.setEmpresaSelect(res);
          const message = `Voce alternou para ${res.nome.toUpperCase()}`
          this.loadingService.presentToast(message);
          this.carregarEmpresas(0, res.id!);
        }
        await this.loadingService.dismissLoading()
      })
    ).subscribe();
  }

  carregarEmpresasDesativadas(userId: number) {
    this.empresaService.carregaEmpresasByAdminId(userId, false).pipe(
      tap((res) => {
        this.empresaService.addEmpresas(res);
      })
    ).subscribe();
  }

  private criaUserAdm(usuario: Usuario, cpf: string, funcao: string, name: string) {
    this.adminUserService.criaAdmin({
      ativo: true,
      cpf: cpf,
      email: usuario.email,
      deletado: false,
      funcao: funcao,
      loginId: usuario.id,
      nome: name
    }).pipe(
      map((res) => {
        if (res) {
          return res;
        }
        return {} as UserAdmin
      }),
      tap((_) => {
        this.loadingService.presentToast('Conta Criada com sucesso');
        this.usuarioService.setLogged(false);
        this.router.navigate(['empresa', 'create'])
      })
    ).subscribe();
  }

  public criaEmpresa(cnpj: string, name: string) {
    this.empresaService.criaEmpresa({
      ativo: true,
      cnpj,
      nome: name,
    }).pipe(
      map((res) => {
        if (res) {
          return res;
        }
        return {} as Empresa
      }),
      tap((res) => {
        this.usuarioService.setLogged(true);
        // this.empresaService.setEmpresaSelect(res);
        // this.carregarEmpresas(this.usuarioService.getUser());
        // this.logout();
        this.router.navigate([''])
      })
    ).subscribe()
  }

  async carregarEmpresas(userId: number, empresaId?: number) {
    await this.loadingService.presentLoading()
    if (empresaId) {
      this.empresaId = empresaId;
      // await this.loadingService.dismissLoading();
      // this.setEmpresaSelect(empresaId)
      this.carregarLocais();
      this.carregarColab();
      return;
    }
    this.empresaService.carregaEmpresasByAdminId(userId, true).pipe(
      catchError((err: any) => {
        if (err.error.error == 'Unauthorized') {
          this.logout();
          this.loadingService.presentToast('Token invalido, sessao finalizada!');
          return throwError(err);
        }
        return throwError(err);
      }),
      tap(async (res) => {
        if (!res[0]) {
          this.router.navigate(['login']);
          this.usuarioService.setLogged(false);
          await this.loadingService.dismissLoading();
          this.loadingService.presentAlertConfirmOrCancel(
            'Empresa', '', 'Voce nao possui nenhum empresa, deseja criar uma?', () => this.router.navigate(['empresa', 'create']), () => this.logout())
          return;
        }
        this.empresaId = res[0].id!;
        this.adminUserService.setUserAdmin(res[0].adminUser as UserAdmin)
        this.carregarEmpresasDesativadas(userId);
        this.carregarLocais();
        this.carregarColab();
        console.log(this.empresaId)
      })
    ).subscribe();
  }

  async carregarLocais() {
    console.log(this.empresaId)
    this.localService.carregaLocais(this.empresaId).pipe(
      tap((res) => {
        this.carregarEscalas(res)
      })
    )
      .subscribe();
  }

  private async carregarColab() {
    this.colaboradorService.carregaColaboradores(this.empresaId).pipe(
      tap(async (_) => await this.loadingService.dismissLoading())
    )
      .subscribe();
  }

  private carregarEscalas(locais: Local[]) {
    if (locais.length <= 0) return;
    console.log('pass')
    for (let local of locais) {
      console.log(`local is loading ${local.id}`)
      this.escalaService.carregaEscalas(local.id!).pipe(
        tap((res) => {
          this.carregaTurnos(res);
          this.carregaSetores(res);
        })
      ).subscribe()
    }
  }

  private carregaTurnos(escalas: Escala[]) {
    console.log('1')
    if (escalas.length <= 0) return;
    for (let escala of escalas) {
      this.turnoService.carregaTurnos(escala.id!).subscribe()
    }
  }

  private carregaSetores(escalas: Escala[]) {
    console.log('2')
    if (escalas.length <= 0) return;
    for (let escala of escalas) {
      // for(let setor of escala.setores!) {
      //   this.turnoService.carregaSetorPorEscala(escala.id, setor.id).subscribe()
      // }
      this.turnoService.carregaSetores(escala.id!).subscribe()
    }
  }

  logout() {
    this.router.navigate(['login'])
    this.usuarioService.logout();
    this.escalaService.limparData();
  }
}
