<ion-toolbar>
  <ion-buttons slot="end">
    <ion-button (click)="close()"
      ><ion-icon name="close-outline"></ion-icon
    ></ion-button>
  </ion-buttons>
</ion-toolbar>
<ion-content class="ion-padding">
  <div class="container">
    <h1>SETORES</h1>

    <div class="sectors">

      <setor-item [isForm]="isInput" *ngIf="isInput" (FormEmitter)="isInput = $event"></setor-item>

      <div *ngIf="(Setores$ | async) as setores">
        <setor-item [isForm]="false" *ngFor="let item of setores" [Setor]="item"></setor-item>
      </div>

      <div class="create" (click)="showInput()"><ion-icon name="add-outline"></ion-icon> criar setor</div>
    </div>
  </div>


</ion-content>
