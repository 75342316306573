import { Injectable } from "@angular/core";
import { Observable, catchError, filter, find, flatMap, map, shareReplay, tap, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Colaborador } from "src/app/models/colaborador";
import { ColaboradorService } from "./colaborador.service";
import { EndPoint, getEndpoint } from "src/app/models/endpoint";
import { ScaleStore } from "../Scale.store";
import { AuthStore } from "../Auth.store";
import { LoadingService } from "../loading.service";

@Injectable()
export class ColaboradorHttpService extends ColaboradorService {

  constructor(private http: HttpClient, private scaleStore: ScaleStore, private authStore: AuthStore, private loadingService: LoadingService) {
    super();
  }

  public carregaColaboradores(empresaId: number): Observable<Colaborador[]> {
    return this.http.get<Colaborador[]>(`${getEndpoint(EndPoint.COLABORADOR)}?empresaId=${empresaId}`).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return [];
      }),
      catchError((err: any) => {
        const message = "Erro ao carregar os colaboradores."
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((response) => {
        console.log('close colab')
        this.scaleStore.setColaboradores(response)
      }),
    );
  }

  public criaColaborado(colaborador: Colaborador): Observable<Colaborador> {
    this.loadingService.presentLoading();
    return this.http.post<Colaborador>(getEndpoint(EndPoint.COLABORADOR), {
      ...colaborador,
      empresa: this.scaleStore.getEmpresaSelected().id,
      loginId: this.authStore.getUser().id,
      deletado: false
    }).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return {} as Colaborador;
      }),
      catchError((err: any) => {
        this.loadingService.dismissLoading()
        let message = "Erro ao criar um colaborador"
        if (err.error.includes('colaborador_cpf_idx')) {
          message = "CPF já cadastrado"
        }
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((res) => {
        this.loadingService.dismissLoading()
        this.scaleStore.addColaborador(res)
      }),

    )
  }

  public atualizarColaborador(usuarioId: number, newColaborador: Colaborador): Observable<Colaborador> {
    this.loadingService.presentLoading();
    return this.http.put<Colaborador>(getEndpoint(EndPoint.COLABORADOR), {
      ...newColaborador,
      id: usuarioId,
      empresa: this.scaleStore.getEmpresaSelected().id,
      loginId: this.authStore.getUser().id,
    }).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return {} as Colaborador;
      }),
      catchError((err: any) => {
        this.loadingService.dismissLoading()
        const message = "Erro ao atualizar um colaborador"
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
      tap((res) => {
        this.loadingService.dismissLoading()
        this.scaleStore.updateColaborador(res)
      })
    )
  }

  public filtrarColaborador(usuarioId: number): Observable<Colaborador> {
    // return this.http.get<Colaborador>(`${getEndpoint(EndPoint.COLABORADOR)}?user=${usuarioId}`).pipe(
    //   map((response) => {
    //     if (response) {
    //       return response;
    //     }
    //     return {} as Colaborador;
    //   })
    // )
    return this.getColaboradores().pipe(
      map((response) => {
        return response.find(col => col.id === usuarioId)!
      })
    )
  }

  public carregaGestores(): Observable<Colaborador[]> {
    // return this.http.get<Colaborador[]>(`${getEndpoint(EndPoint.COLABORADOR)}?filter=gestor`).pipe(
    //   map((response) => {
    //     if (response) {
    //       return response;
    //     }
    //     return {} as Colaborador[];
    //   })
    // )
    return this.getColaboradores().pipe(
      map((response) => {
        if (response) {
          return response.filter(colab => colab.gestor === true);
        }
        return [];
      }),
    )
  }

  public deletarColaborador(usuarioId: number): void {
    this.loadingService.presentLoading();
    this.http.delete(`${getEndpoint(EndPoint.COLABORADOR)}?id=${usuarioId}`).pipe(
      tap((_) => {
        this.scaleStore.removeColaborador(usuarioId)
        this.loadingService.dismissLoading()
      }),
      catchError((err: any) => {
        this.loadingService.dismissLoading()
        const message = "Erro ao excluir um colaborador"
        this.loadingService.presentToast(message)
        return throwError(err);
      }),
    ).subscribe();
  }

  public override getColaboradores(): Observable<Colaborador[]> {
    return this.scaleStore.colaboradores$;
  }


  public override getColaboradoresAtivos(): Observable<Colaborador[]> {
    return this.scaleStore.colaboradores$.pipe(
      map((colaboradores) => colaboradores.filter(c => c.ativo))
    );
  }

  public criarAfastamento(tipo: string, colaborador: number, inicio: string, fim: string): Observable<any> {
    return this.http.post(`${getEndpoint(EndPoint.COLABORADOR)}/save/afastamento`, {
      colaborador: colaborador,
      fim: new Date(fim),
      inicio: new Date(inicio),
      tipo
    })
      .pipe(
        tap((r) => {
          this.scaleStore.addEventInCollaborator(colaborador, r);
          console.log(r);
        })
      )
  }

  public removerAfastamento(id: number): Observable<any> {
    return this.http.delete(`${getEndpoint(EndPoint.COLABORADOR)}/delete/afastamento?id=${id}`)
      .pipe(
        tap((r) => {
          console.log(r);
        })
      )
  }

}
