import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';

import { IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { LocalService } from './services/local/local.service';
import { LocalMockService } from './services/local/local-mock.service';
import { EscalaService } from './services/escala/escala.service';
import { EscalaMockService } from './services/escala/escala-mock.service';
import { ColaboradorMockService } from './services/colaborador/colaborador-mock.service';
import { JornadaService } from './services/jornada/jornada.service';
import { JornadaMockService } from './services/jornada/jornada-mock.service';
import { TrocaService } from './services/troca/troca.service';
import { TrocaMockService } from './services/troca/troca-mock.service';
import { TurnoService } from './services/turno/turno.service';
import { TurnoMockService } from './services/turno/turno-mock.service';
import { CoreModule } from './core/core.module';
import { EmpresaService } from './services/empresa/empresa.service';
import { EmpresaMockService } from './services/empresa/empresa-mock.service';
import { AdminService } from './services/admin/admin.service';
import { AdminMockService } from './services/admin/admin-mock.service';
import { ColaboradorService } from './services/colaborador/colaborador.service';
import { UsuarioService } from './services/usuario/usuario.service';
import { UsuarioHttpService } from './services/usuario/usuario-http.service';
import { LocalHttpService } from './services/local/local-http.service';
import { EmpresaHttpService } from './services/empresa/empresa-http.service';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ColaboradorHttpService } from './services/colaborador/colaborador-http.service';
import { EscalaHttpService } from './services/escala/escala-http.service';
import { AdminHttpService } from './services/admin/admin-http.service';
import { ModalsModule } from './modals/modals.module';
import { TurnoHttpService } from './services/turno/turno-http.service';
import { UsuarioMockService } from './services/usuario/usuario-mock.service';
import { SugestaoService } from './services/sugestao/sugestao.service';
import { SugestaoHttpService } from './services/sugestao/sugestao-http.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    ModalsModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

    //services - mock
    // { provide: JornadaService, useClass: JornadaMockService },
    // { provide: TrocaService, useClass: TrocaMockService },

    // { provide: AdminService, useClass: AdminMockService },
    // { provide: TurnoService, useClass: TurnoMockService },
    // { provide: EscalaService, useClass: EscalaMockService },
    // { provide: ColaboradorService, useClass: ColaboradorMockService },
    // { provide: EmpresaService, useClass: EmpresaMockService },
    // { provide: LocalService, useClass: LocalMockService },
    // { provide: UsuarioService, useClass: UsuarioMockService },
    // { provide: SugestaoService, useClass: SugestaoHttpService },// PENDENTE


    //Serviços HTTP
    { provide: JornadaService, useClass: JornadaMockService },//PENDENTE
    { provide: TrocaService, useClass: TrocaMockService },//PENDENTE
    { provide: AdminService, useClass: AdminHttpService },
    { provide: TurnoService, useClass: TurnoHttpService },
    { provide: EscalaService, useClass: EscalaHttpService },
    { provide: ColaboradorService, useClass: ColaboradorHttpService },
    { provide: EmpresaService, useClass: EmpresaHttpService },
    { provide: LocalService, useClass: LocalHttpService },
    { provide: UsuarioService, useClass: UsuarioHttpService },
    { provide: SugestaoService, useClass: SugestaoHttpService },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
