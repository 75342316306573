import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, find, takeUntil, tap } from 'rxjs';
import { getColor } from 'src/app/models/colors';
import { Setor } from 'src/app/models/setor';
import { Turno } from 'src/app/models/turno';
import { TurnoService } from 'src/app/services/turno/turno.service';

@Component({
  selector: 'table-export-pdf',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent  implements OnInit, OnDestroy {


  headColor: string = '#f7a941';
  @Input() tableItems: any;
  @Input() data: any;
  @Input() shifts!: Turno[];
  titleTable: string = ''
  turnos: Turno[] = [];

  setores: Setor[] = [];

  notifier = new Subject()


  constructor(
    private turnoService: TurnoService
  ) {
  }

  ngOnInit() {
    console.log('data table', this.data)
    if(this.data) {
      this.turnoService.getSetorById(this.data.setorId).pipe(
        takeUntil(this.notifier),
        tap((r) => {
          this.titleTable = r.nome;
          this.headColor = getColor(r.cor)
        })
      ).subscribe()
      this.turnoService.getSetores().pipe(
        takeUntil(this.notifier),
        tap((r) => this.setores = r)
      ).subscribe()
    }
  }

  ngOnDestroy(): void {
    this.notifier.next(1)
    this.notifier.complete()
  }

  getSetor(item: any): any {
    console.log(item)
    if(item != undefined && item.teveTroca) {
      const setor = this.setores.find(s => s.id == item.setorId)!
      return {
        'background': getColor(setor.cor),
        'color': '#fff'
      }
    }
    return { 'background': 'transparent'}
  }

  getTurno(turnoId: number) {
    const turno = this.shifts.find(r => r.id == turnoId)
    if(!turno) return {
      sigla: ''
    }
    const newData = {
      ...turno,
      corFormated: getColor(turno!.cor),
    }
    return newData;
  }

  isHoliday(sys: string): boolean {
    if(sys == 'LM' || sys == 'FE') {
      return true;
    }
    return false;
  }

  isPoint(sys: string): boolean {
    if(sys == 'MT' || sys == 'SD') {
      return true;
    }
    return false;
  }

}
