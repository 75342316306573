<div class="item" *ngIf="!isForm; else input">
  <div class="sector">
    <div class="color" [ngStyle]="{'background-color': colorSelected}"></div>
    <p class="name">{{ setorName }}</p>
    <div class="checkbox">
      <label class="container">
        <input type="checkbox" [(ngModel)]="active" disabled />
        <div class="checkmark"></div>
      </label>
      <span>Ativo</span>
    </div>
    <div class="actions">
      <ion-icon name="create-outline" (click)="setEditMode()" ></ion-icon>
      <ion-icon name="trash-outline" (click)="deleteSetor()" ></ion-icon>
    </div>
  </div>
</div>

<ng-template #input>

  <div class="sector" [formGroup]="formGroup">
    <div class="color" [ngStyle]="{'background-color': colorSelected}" (click)="openPopover($event)"></div>
    <input class="sector-name" type="text" formControlName="nome" />
    <div class="checkbox">
      <label class="container">
        <input type="checkbox" formControlName="ativo" />
        <div class="checkmark"></div>
      </label>
      <span>Ativo</span>
    </div>
    <div class="actions">
      <ion-icon *ngIf="!isSaving" name="checkmark-outline" (click)="submit()" ></ion-icon>
      <ion-spinner *ngIf="isSaving" class="save"></ion-spinner>
      <ion-icon name="close-outline" (click)="cancel()" ></ion-icon>
    </div>
  </div>
</ng-template>
