import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subject, takeUntil, tap } from 'rxjs';
import { ForgotPasswordModalComponent } from 'src/app/modals/components/forgot-password/forgot-password.component';
import { AuthStore } from 'src/app/services/Auth.store';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  formLogin!: FormGroup;

  version: string = environment.version;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private modalCtrl: ModalController
    ) {}

  // private _destroy$: Subject<void> = new Subject<void>();

  // ngOnDestroy() {
  //   this._destroy$.next();
  //   this._destroy$.complete();
  // }

  ngOnInit() {
    this.formLogin = new FormGroup({
      email: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.email],
      }),
      password: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.min(8)],
      }),
    });
  }

  sendTo() {
    this.router.navigate(['signup'])
  }


  submit() {
    if(this.formLogin.invalid) return;
    this.loginService.login(this.formLogin.value.email, this.formLogin.value.password);
    // this.authStore.login(this.formLogin.value.email, this.formLogin.value.password).subscribe(
    //   () => {
    //     this.router.navigate(['']);
    //   },
    //   err => {
    //     alert(err);
    //   }
    // )
    // this.usuario.login(this.formLogin.value.email, this.formLogin.value.password)
    // .pipe(takeUntil(this._destroy$)).subscribe({
    //   next: (success) => {
    //     if (success) {
    //       this.carregarEmpresa();
    //       this.router.navigate(['/'])
    //     }
    //   },
    //   error: () => {
    //     console.log('error')
    //   }
    // })
    console.log(this.formLogin.value)
  }

  async openModalForgot() {
    const modal = await this.modalCtrl.create({
      component: ForgotPasswordModalComponent,
      initialBreakpoint: 0.7,
      breakpoints: [0.7],
      cssClass: 'full-wi'
    })

    modal.present();
  }

}
